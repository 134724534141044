import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/ecm/')

/**
 * @param {Number} accountingFirmId
 * @param {Number} treeStructureId
 */
const addTreeStructureToList = (accountingFirmId, treeStructureId) => {
	const url = `accounting-firms/${accountingFirmId}/catalog-tree-structures/${treeStructureId}`
	return axios.post(url)
}

/**
 *
 * @param {Number} accountingFirmId
 * @param {Number} treeStructureId
 */
const getTreeStructure = (accountingFirmId, treeStructureId) => {
	const url = `accounting-firms/${accountingFirmId}/catalog-tree-structures/${treeStructureId}`
	return axios.get(url)
}

/**
 *
 * @param {Number} accountingFirmId
 * @param {Object} params
 */
const getTreeStructures = accountingFirmId => {
	const url = `accounting-firms/${accountingFirmId}/catalog-tree-structures`
	return axios.get(url)
}

/**
 *
 * @param {Number} accountingFirmId
 * @param {Number} treeStructureId
 */
const removeTreeStructureFromList = (accountingFirmId, treeStructureId) => {
	const url = `accounting-firms/${accountingFirmId}/catalog-tree-structures/${treeStructureId}`
	return axios.delete(url)
}

/**
 *
 * @param {Number} accountingFirmId
 * @param {Number} treeStructureId
 */
const setTreeStructureAsDefaultForAccountingFirm = (accountingFirmId, treeStructureId) => {
	const url = `accounting-firms/${accountingFirmId}/catalog-tree-structures`
	const data = {'catalog_tree_structure_id': treeStructureId}
	return axios.patch(url, data)
}

/**
 * @param {Number} accountingFirmId
 * @param {Number} treeStructureId
 */
const setTreeStructureAsDefaultForCustomers = (accountingFirmId, treeStructureId) => {
	const data = {'catalog_tree_structure_id': treeStructureId}
	const url = `accounting-firms/${accountingFirmId}/accounting-firms-catalog-tree-structures`
	return axios.post(url, data)
}

export default {
	addTreeStructureToList: addTreeStructureToList,
	getTreeStructure: getTreeStructure,
	getTreeStructures: getTreeStructures,
	removeTreeStructureFromList: removeTreeStructureFromList,
	setTreeStructureAsDefaultForAccountingFirm: setTreeStructureAsDefaultForAccountingFirm,
	setTreeStructureAsDefaultForCustomers: setTreeStructureAsDefaultForCustomers
}
