<template>
	<v-layout id="ecm-catalog-container" column fill-height scroll-y>
		<v-flex :scroll-y="$vuetify.breakpoint.mdAndUp" :loading="loading">
			<ECMCatalogSettings :accounting-firms-ecm="accountingFirmsECM" :available-ecm="availableECM" />
		</v-flex>
		<v-flex :scroll-y="$vuetify.breakpoint.mdAndUp">
			<v-layout :fill-height="$vuetify.breakpoint.mdAndUp" row wrap>
				<v-flex :loading="loading" :class="treeStructureSelected ? 'xs12 md6' : 'xs12'" fill-height>
					<WSection fill-height no-margin :title="$t('ecm-catalog.ecm_models') + ' :'">
						<ECMCatalogList v-model="treeStructures"  />
					</WSection>
				</v-flex>
				<v-flex v-if="treeStructureSelected" fill-height xs12 md6>
					<ECMCatalogForm v-model="treeStructureSelected.id"  />
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import ECMCatalogModuleGuard from '@/mixins/ModulesGuards/ECMCatalog/ECMCatalogModuleGuard'

export default {
	name: 'ECMCatalog',
	components: {
		ECMCatalogForm: () => ({
			component: import('@/components/ECMCatalog/ECMCatalogForm')
		}),
		ECMCatalogList: () => ({
			component: import('@/components/ECMCatalog/ECMCatalogList')
		}),
		ECMCatalogSettings: () => ({
			component: import('@/components/ECMCatalog/ECMCatalogSettings')
		})
	},
	mixins: [ECMCatalogModuleGuard],
	data: function () {
		return {
			accountingFirmsECM: {},
			availableECM: [],
			treeStructures: [],
			treeStructureSelected: null,
			loading: false,
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected
		})
	},
	watch: {
		'accountingFirm.catalog_tree_structure_id': {
			handler: function (val) {
				if (val) {
					this.getAccountingFirmsECM()
				}
			}
		},
		accountingFirm: {
			handler: function (newValue, oldValue) {
				if (newValue && (!oldValue || newValue != oldValue)) {
					this.getTreeStructures()
				}
			}
		}
	},
	created: function () {
		this.getTreeStructures()
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.TREE_STRUCTURE_ADDED, action: this.onTreeStructureAddedToList },
				{ event: this.events.TREE_STRUCTURE_REMOVED, action: this.onTreeStructureRemovedFromList },
				{ event: this.events.TREE_STRUCTURE_SELECTED, action: this.onTreeStructureSelected },
				{ event: this.events.TREE_STRUCTURE_SETTED_AS_DEFAULT_FOR_CUSTOMERS, action: this.onTreeStructureSelectedAsDefaultForCustomers }
			]
		},
		getAccountingFirmsECM: function () {
			if (this.accountingFirm && this.accountingFirm.catalog_tree_structure_id) {
				const catalogTreeStructureId = this.accountingFirm.catalog_tree_structure_id
				const treeStructure = this.treeStructures.find(ts => ts.id == catalogTreeStructureId)
				if (treeStructure) {
					this.accountingFirmsECM = treeStructure
				}
			}
		},
		getAvailableECMs: function () {
			this.availableECM = this.treeStructures.filter(item => item.has_access)
		},
		getTreeStructures: function () {
			this.loading = true
			return this.service
				.getTreeStructures()
				.then(treeStructures => {
					this.treeStructures = treeStructures
					this.getAccountingFirmsECM()
					this.getAvailableECMs()
					return this.treeStructures
				})
				.finally(() => {
					this.loading = false
				})
		},
		onTreeStructureAddedToList: function (treeStructure) {
			this.treeStructures = this.treeStructures.map(ts => {
				if (ts.id == treeStructure.id) {
					ts.has_access = true
				}
				return ts
			})
			this.getAvailableECMs()
		},
		onTreeStructureRemovedFromList: function (treeStructure) {
			this.treeStructures = this.treeStructures.map(ts => {
				if (ts.id == treeStructure.id) {
					ts.has_access = false
				}
				return ts
			})
			this.getAvailableECMs()
		},
		onTreeStructureSelected: function (treeStructure) {
			this.treeStructureSelected = treeStructure
		},
		onTreeStructureSelectedAsDefaultForCustomers: function (treeStructureId) {
			this.treeStructures = this.treeStructures.map(ts => {
				ts.is_default_for_customers = ts.id === treeStructureId
				return ts
			})
		}
	}
}
</script>

<style scoped>
@media (min-width: 960px) {
	#ecm-catalog-container {
		display: grid;
		grid-template-rows: 1fr 1fr;
	}
}

#ecm-catalog-container div:first-child {
	grid-row: 1;
}

#ecm-catalog-container div:nth-child(2) {
	grid-row: 2;
}
</style>
