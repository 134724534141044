// ============================================================================
// ECMCatalogService
// -----------------
// ECMCatalog module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/ECMCatalogApi'
import { Bus as BUS, Events as EVENTS } from '@/events/ECMCatalog/ECMCatalogEvents'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

// -------
// Exports
// -------
export default {
	addTreeStructureToList: function (treeStructure) {
		return API.addTreeStructureToList(store.state.accountingFirm?.selected?.id, treeStructure.id).then(() => {
			BUS.emit(EVENTS.TREE_STRUCTURE_ADDED, treeStructure)
		})
	},
	getTreeStructure: function (treeStructureId) {
		return API.getTreeStructure(store.state.accountingFirm?.selected?.id, treeStructureId).then(res => res.data.data)
	},
	getTreeStructures: function () {
		return API.getTreeStructures(store.state.accountingFirm?.selected?.id).then(res => res.data.data)
	},
	removeTreeStructureFromList: function (treeStructure) {
		return window.vueInstance.$dialog
			.warning({
				text: window.vueInstance.$t('ecm-catalog.remove_tree_structure_from_list.are_you_sure'),
				title: window.vueInstance.$t('ecm-catalog.remove_tree_structure_from_list.title'),
				actions: {
					false: window.vueInstance.$t('actions.no'),
					true: window.vueInstance.$t('actions.yes')
				}
			})
			.then(res => {
				if (res) {
					API.removeTreeStructureFromList(store.state.accountingFirm?.selected?.id, treeStructure.id).then(() => {
						BUS.emit(EVENTS.TREE_STRUCTURE_REMOVED, treeStructure)
					})
				}
			})
	},
	setTreeStructureAsDefaultForAccountingFirm: function (treeStructure) {
		return window.vueInstance.$dialog
			.warning({
				text: window.vueInstance.$t('ecm-catalog.select_model_as_default_for_firms.are_you_sure'),
				title: window.vueInstance.$t('ecm-catalog.select_model_as_default_for_firms.title', { name: treeStructure.name }),
				actions: {
					false: window.vueInstance.$t('actions.no'),
					true: window.vueInstance.$t('actions.yes')
				}
			})
			.then(res => {
				if (res) {
					API.setTreeStructureAsDefaultForAccountingFirm(store.state.accountingFirm?.selected?.id, treeStructure.id).then(() => {
						AppEventBus.emit(AppEvents.ACCOUNTING_FIRM_UPDATED, { catalog_tree_structure_id: treeStructure.id, id: store.state.accountingFirm?.selected?.id })
					})
				}
			})
	},
	setTreeStructureAsDefaultForCustomers: function (treeStructureId) {
		return API.setTreeStructureAsDefaultForCustomers(store.state.accountingFirm?.selected?.id, treeStructureId).then(() => {
			BUS.emit(EVENTS.TREE_STRUCTURE_SETTED_AS_DEFAULT_FOR_CUSTOMERS, treeStructureId)
			return treeStructureId
		})
	}
}
