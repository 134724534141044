import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
	TREE_STRUCTURE_ADDED: 'tree-structure-added',
	TREE_STRUCTURE_SETTED_AS_DEFAULT_FOR_CUSTOMERS: 'tree-structure-setted-to-default-for-customers',
	TREE_STRUCTURE_REMOVED: 'tree-structure-removed',
	TREE_STRUCTURE_SELECTED: 'tree-structure-selected'
})
